@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'AvantGardeGothicMedium';
  src: local('AvantGardeGothicMedium'), url(./assets/fonts/ITC-Avant-Garde-Gothic-Medium.ttf);
}

@font-face {
  font-family: 'AvantGardeGothicDemi';
  src: local('AvantGardeGothicDemi'), url(./assets/fonts/ITC-Avant-Garde-Gothic-Demi.ttf);
}

@layer utilities {
  .bg-brand-gradient {
    background-image: linear-gradient(#3490dc, #6574cd) !important;
  }

  .line-height-lg {
    line-height: 2rem !important;
  }

  .line-height-xl {
    line-height: 2.6rem !important;
  }
}

@layer base {
  * {
    @apply font-primary;
  }
}

body {
  background-color: black;
}

html, body, #root {
  overflow: hidden;
}

.word-spacing-md {
  word-spacing: 0.1rem;
}